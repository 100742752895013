import { isServer } from '@lib/utils';

import { config } from '../config';

import { LANGUAGES_AVAILABLE, LANGUAGES_FALLBACK } from './i18n.settings';
import { Locale } from './types';

export const getLanguages = (tenantUid: string): Locale[] => {
  if (isServer()) {
    /**
     * TODO FIX THIS LOOKUP ON CMS LANGUAGES THEN
     */
    if (LANGUAGES_AVAILABLE.includes(tenantUid as Locale)) {
      return [LANGUAGES_FALLBACK];
    }

    const cfg = config.getClientConfigByTenantUid(tenantUid);

    return cfg?.languages.languages || [LANGUAGES_FALLBACK];
  }

  return window._env_?.languages || [LANGUAGES_FALLBACK];
};
