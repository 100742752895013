import chalk from 'chalk';

import { LogLevel } from '@lib/types';

const getPrefix = (logLevel: LogLevel, prefix?: string) => {
  let level = `[${new Date().toISOString()}] [${logLevel.toUpperCase()}]`;
  switch (logLevel) {
    case 'warn':
      level = chalk.yellow(level);
      break;
    case 'info':
      level = chalk.green(level);
      break;
    case 'error':
      level = chalk.red(level);
      break;
    case 'debug':
      level = chalk.blue(level);
      break;
    case 'log':
      level = chalk.blue(level);
      break;
    default:
      break;
  }

  if (prefix) {
    level += ` ${chalk.blueBright(`[${prefix}]`)}`;
  }

  return level;
};

const getLogger = (prefix?: string, logLevel?: LogLevel) => {
  const defaultLogLevel = process.env.LOG_LEVEL || 'debug';

  const level = logLevel || defaultLogLevel;

  return {
    level,
    prefix,
    debug: (...args: any[]) => {
      if (level !== 'debug') return;

      if (args[0] && typeof args[0] === 'string') {
        args[0] = `${getPrefix('debug', prefix)} ${args[0]}`;
      }

      console.debug(...args);
    },
    warn: (...args: any[]) => {
      if (level === 'error') return;

      if (args[0] && typeof args[0] === 'string') {
        args[0] = `${getPrefix('warn', prefix)} ${args[0]}`;
      }

      console.warn(...args);
    },
    info: (...args: any[]) => {
      if (level === 'error' || level === 'warn') return;

      if (args[0] && typeof args[0] === 'string') {
        args[0] = `${getPrefix('info', prefix)} ${args[0]}`;
      }

      console.info(...args);
    },
    error: (...args: any[]) => {
      if (args[0] && typeof args[0] === 'string') {
        args[0] = `${(getPrefix('error'), prefix)} ${args[0]}`;
      }

      console.error(...args);
    }
  };
};

type Logger = ReturnType<typeof getLogger>;

const log = getLogger();

export { log, getLogger };
export type { Logger };
