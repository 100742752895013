import { keys } from 'remeda';

import { CAPTCHA_TEST_KEYS } from '@lib/config';
import { getLogger, log, Logger } from '@lib/services/logger';

import { LANGUAGES_AVAILABLE, Locale } from '../i18n';

import {
  ClientConfig,
  ClientConfigAnalytics,
  ClientConfigAnalyticsSolution,
  ClientConfigConcierge,
  ClientConfigContactForm,
  ClientConfigLanguages,
  ClientConfigSearch,
  ClientConfigSmtp
} from './configService.types';

const MATCH_TENANT_ID = /^PT__.*__TENANT_UID$/;

class ConfigService {
  private logger: Logger = {} as Logger;
  private clientConfigs: ClientConfig[] = [];
  private fallbackClientId: string | undefined;

  constructor() {
    this.logger = getLogger('CONFIG');
    this.clientConfigs = this.parseClientConfigs();
    this.logger.debug('Client configs loaded', this.clientConfigs);
    this.fallbackClientId = process.env.PT_FALLBACK_TENANT;
    if (!this.fallbackClientId) {
      this.logger.debug('Missing fallback client ID: provide env param PT_FALLBACK_TENANT');
    }
  }

  public getClientConfigs(): ClientConfig[] {
    return this.clientConfigs;
  }

  public getClientConfigByTenantUid(tenantUid: string): ClientConfig | null {
    if (LANGUAGES_AVAILABLE.includes(tenantUid as Locale)) {
      return this.getFallbackClientConfig();
    }
    const cfg = this.clientConfigs.find(c => c.tenantUid === tenantUid);

    if (!cfg) {
      this.logger.info(
        `No client config found for ID '${tenantUid}' -> falling back to fallback: ${this.fallbackClientId}`
      );

      return this.getFallbackClientConfig();
    }

    return cfg;
  }

  public getClientConfigByHost(host: string): ClientConfig | null {
    const cfg = this.clientConfigs.find(c => c.hosts.includes(host));

    if (!cfg) {
      this.logger.info(
        `No client config found for host '${host}' -> falling back to fallback: ${this.fallbackClientId}`
      );

      return this.getFallbackClientConfig();
    }

    return cfg;
  }

  public getFallbackClientConfig(): ClientConfig | null {
    if (!this.fallbackClientId) {
      this.logger.info('No fallback client ID provided');

      return null;
    }

    const match = this.clientConfigs.find(c => c.tenantUid === this.fallbackClientId);

    if (!match) {
      this.logger.info(`Fallback client ID '${this.fallbackClientId}' not found`);

      return null;
    }

    return match;
  }

  private parseClientConfigs() {
    const env = process.env;
    const clientConfigs: ClientConfig[] = [];
    const envKeys = keys(env);

    for (const key of envKeys) {
      if (MATCH_TENANT_ID.test(key)) {
        this.logger.debug(`Parsing client config for key '${key}'`);
        const keyId = key.replace(/PT__(.*)__TENANT_UID/, '$1');

        this.logger.debug(`Parsing client config for key ID '${keyId}'`);

        const id = env[key];
        if (!id) {
          log.error(`Missing client ID for client '${keyId}'`);
          break;
        }

        if (clientConfigs.find(c => c.tenantUid === id)) {
          log.error(`Duplicate client ID '${id}' found`);
          break;
        }

        /**
         * Hosts
         */
        let hosts: string[] = [];
        const hostsStr = env[`PT__${keyId}__HOSTS`];
        if (!hostsStr) {
          log.error(
            `Missing HOSTS for client ${keyId}. Important: hosts are required to determine client config mapping.`
          );
          break;
        } else {
          hosts = hostsStr.split(',').map(host => host.trim());
        }

        /**
         * Languages
         */
        const lngLanguagesStr = env[`PT__${keyId}__LANGUAGES`];
        if (!lngLanguagesStr) {
          log.error(`Missing LANGUAGES for client ${keyId}`);
          break;
        }
        const lngDefaultLanguage = env[`PT__${keyId}__DEFAULT_LANGUAGE`] as Locale;
        if (!lngDefaultLanguage) {
          log.error(`Missing DEFAULT_LANGUAGE for client ${keyId}`);
          break;
        }
        const lngLanguages = lngLanguagesStr.split(',').map(lng => lng.trim()) as Locale[];
        if (!lngLanguages.includes(lngDefaultLanguage)) {
          log.error(`DEFAULT_LANGUAGE must be one of the LANGUAGES for client ${keyId}`);
          break;
        }

        const languages: ClientConfigLanguages = {
          languages: lngLanguages,
          defaultLanguage: lngDefaultLanguage
        };

        /**
         * robots.txt
         */
        const isRobotsEnabled = env[`PT__${keyId}__ROBOTS_ENABLED`] === 'true';

        /**
         * Search
         */
        let isSearchEnabled = false;
        if (env[`PT__${keyId}__SEARCH_ENABLED`] === 'true') {
          isSearchEnabled = true;
        }
        const searchUrl = env[`PT__${keyId}__SEARCH_MEILISEARCH_URL`];
        if (!searchUrl && isSearchEnabled) {
          log.error(`Missing SEARCH_MEILISEARCH_URL for client ${keyId}`);
          break;
        }
        const searchIndexPrefix = env[`PT__${keyId}__SEARCH_MEILISEARCH_INDEX_PREFIX`];
        if (!searchIndexPrefix && isSearchEnabled) {
          log.error(`Missing SEARCH_MEILISEARCH_INDEX_PREFIX for client ${keyId}`);
          break;
        }
        const searchApiKey = env[`PT__${keyId}__SEARCH_MEILISEARCH_API_KEY`];
        if (!searchApiKey && isSearchEnabled) {
          log.error(`Missing SEARCH_MEILISEARCH_API_KEY for client ${keyId}`);
          break;
        }
        const search: ClientConfigSearch = {
          enabled: isSearchEnabled,
          meilisearchUrl: searchUrl || '',
          meilisearchIndexPrefix: searchIndexPrefix || '',
          meilisearchApiKey: searchApiKey || ''
        };

        /**
         * Analytics
         */
        let isAnalyticsEnabled = false;
        if (env[`PT__${keyId}__ANALYTICS_ENABLED`] === 'true') {
          isAnalyticsEnabled = true;
        }
        const analyticsSolution = env[`PT__${keyId}__ANALYTICS_SOLUTION`];
        if (!analyticsSolution && isAnalyticsEnabled) {
          log.error(`Missing ANALYTICS_SOLUTION for client ${keyId}`);
          break;
        }
        if (
          analyticsSolution !== 'GOOGLE_TAG_MANAGER' &&
          analyticsSolution !== 'GOOGLE_ANALYTICS'
        ) {
          log.error(`Invalid ANALYTICS_SOLUTION for client ${keyId}`, analyticsSolution);
          break;
        }
        const analyticsClientId = env[`PT__${keyId}__ANALYTICS_CLIENT_ID`];
        if (!analyticsClientId && isAnalyticsEnabled) {
          log.error(`Missing ANALYTICS_CLIENT_ID for client ${keyId}`);
          break;
        }
        let isCookieBannerEnabled = false;
        if (env[`PT__${keyId}__ANALYTICS_COOKIE_BANNER_ENABLED`] === 'true') {
          isCookieBannerEnabled = true;
        }
        let isThirdPartyCookiesEnabled = false;
        if (env[`PT__${keyId}__ANALYTICS_THIRD_PARTY_COOKIES_ENABLED`] === 'true') {
          isThirdPartyCookiesEnabled = true;
        }
        const analytics: ClientConfigAnalytics = {
          enabled: isAnalyticsEnabled,
          solution: analyticsSolution as ClientConfigAnalyticsSolution,
          clientId: analyticsClientId || '',
          cookieBannerEnabled: isCookieBannerEnabled,
          thirdPartyCookiesEnabled: isThirdPartyCookiesEnabled
        };

        /**
         * Contact form
         */
        let isRecaptchaEnabled = false;
        if (env[`PT__${keyId}__CONTACT_FORM_RECAPTCHA_ENABLED`] === 'true') {
          isRecaptchaEnabled = true;
        }
        let recaptchaSiteKey = env[`PT__${keyId}__CONTACT_FORM_RECAPTCHA_SITE_KEY`];
        if (!recaptchaSiteKey && isRecaptchaEnabled) {
          log.error(`Missing CONTACT_FORM_RECAPTCHA_SITE_KEY for client ${keyId}`);
          break;
        }
        let recaptchaSecretKey = env[`PT__${keyId}__CONTACT_FORM_RECAPTCHA_SECRET_KEY`];
        if (!recaptchaSecretKey && isRecaptchaEnabled) {
          log.error(`Missing CONTACT_FORM_RECAPTCHA_SECRET_KEY for client ${keyId}`);
          break;
        }

        if (process.env.NODE_ENV === 'development' && isRecaptchaEnabled) {
          log.debug('Using test captcha keys');
          recaptchaSiteKey = CAPTCHA_TEST_KEYS.siteKey;
          recaptchaSecretKey = CAPTCHA_TEST_KEYS.secretKey;
        }

        const contactForm: ClientConfigContactForm = {
          recaptchEnabled: isRecaptchaEnabled,
          recaptchaSiteKey: recaptchaSiteKey || '',
          recaptchaSecretKey: recaptchaSecretKey || ''
        };

        /**
         * SMTP
         */
        const smtpUsername = env[`PT__${keyId}__SMTP_USERNAME`];
        if (!smtpUsername) {
          log.error(`Missing SMTP_USERNAME for client ${keyId}`);
          break;
        }
        const smtpPassword = env[`PT__${keyId}__SMTP_PASSWORD`];
        if (!smtpPassword) {
          log.error(`Missing SMTP_PASSWORD for client ${keyId}`);
          break;
        }
        const smtpHost = env[`PT__${keyId}__SMTP_HOST`];
        if (!smtpHost) {
          log.error(`Missing SMTP_HOST for client ${keyId}`);
          break;
        }
        const smtpPort = env[`PT__${keyId}__SMTP_PORT`];
        if (!smtpPort) {
          log.error(`Missing SMTP_PORT for client ${keyId}`);
          break;
        }
        const isSmptSecure = env[`PT__${keyId}__SMTP_SECURE`] === 'true';
        const smtpSenderEmail = env[`PT__${keyId}__SMTP_SENDER_EMAIL`];
        if (!smtpSenderEmail) {
          log.error(`Missing SMTP_SENDER_EMAIL for client ${keyId}`);
          break;
        }
        const smtpSenderName = env[`PT__${keyId}__SMTP_SENDER_NAME`];
        if (!smtpSenderName) {
          log.error(`Missing SMTP_SENDER_NAME for client ${keyId}`);
          break;
        }
        const smtpRecipientEmailsStr = env[`PT__${keyId}__SMTP_RECIPIENT_EMAILS`];
        if (!smtpRecipientEmailsStr) {
          log.error(`Missing SMTP_RECIPIENT_EMAILS for client ${keyId}`);
          break;
        }
        const smtpRecipientEmails = smtpRecipientEmailsStr.split(',').map(email => email.trim());

        const smtp: ClientConfigSmtp = {
          username: smtpUsername,
          password: smtpPassword,
          host: smtpHost,
          port: parseInt(smtpPort),
          secure: isSmptSecure,
          senderEmail: smtpSenderEmail,
          senderName: smtpSenderName,
          recipientEmails: smtpRecipientEmails
        };

        /**
         * Concierge
         */
        const isConciergeEnabled = env[`PT__${keyId}__CONCIERGE_ENABLED`] === 'true';
        const conciergeJsUrl = env[`PT__${keyId}__CONCIERGE_JS_URL`];
        const conciergeApiUrl = env[`PT__${keyId}__CONCIERGE_API_URL`];

        let concierge: ClientConfigConcierge | undefined = undefined;
        if (isConciergeEnabled) {
          if (!conciergeJsUrl) {
            log.error(`Missing CONCIERGE_JS_URL for client ${keyId}`);
          } else if (!conciergeApiUrl) {
            log.error(`Missing CONCIERGE_API_URL for client ${keyId}`);
          } else {
            concierge = {
              enabled: isConciergeEnabled,
              jsUrl: conciergeJsUrl,
              apiUrl: conciergeApiUrl
            };
          }
        }

        const clientConfig: ClientConfig = {
          tenantUid: id,
          hosts,
          languages,
          robotsEnabled: isRobotsEnabled,
          search,
          analytics,
          contactForm,
          smtp,
          concierge
        };

        clientConfigs.push(clientConfig);
      }
    }

    return clientConfigs;
  }
}

const configService = new ConfigService();

export { configService as config };
